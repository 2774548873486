import React, { useEffect, useRef } from "react"

import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Link from "gatsby-link"
import { TimelineLite } from "gsap"
import AnimatedHeadline from "../components/AnimatedHeadline"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Kontakt from "../components/kontakt"
import CategorySVG from "../svg/category.svg"
import TimeSVG from "../svg/time.svg"
import ogImageDatenschutz from "../images/og-image/og-image-datenschutz.png"

const PostTemplate = props => {
  const post = props.data.wordpressPost
  let image = useRef(null)
  let container = useRef(null)
  /*  let imageReveal = CSSRulePlugin.getRule(".img-container:after") */

  let tl = new TimelineLite()

  let linkPost = typeof window !== "undefined" && window.location.href
  console.log(post)
  console.log(post.featured_media.localFile.publicURL)
  useEffect(() => {
    /*  let currentImage = image.imageRef.current
    tl.to(container, 0, { css: { visibility: "visible" } })
    tl.fromTo(imageReveal, 1.4, { height: "100%" },{ height: "0%", ease: Power2.easeInOut }, 0)
    tl.from(currentImage, 1.4, {
      scale: 1.2,
      ease: Power2.easeInOut,
      delay: -1.4,
    }) */
  }, [])

  function calculateReadingTime(txt) {
    let wordsPerMinute = 150
    let result

    let textLength = txt.split(" ").length // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute)
      result = `${value} Minuten`
    }
    return result
  }

  function formatDate(date) {
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()

    const options = {  year: 'numeric', month: 'long', day: 'numeric' };

  /*   console.log(date.toLocaleDateString('de-DE', options)); */
    return date.toLocaleDateString('de-DE', options)
  }
  return (
    <>
      <SEO
        title={post.title}
        description={post.excerpt}
        image={post.featured_media.localFile.publicURL}
        defaultTitle={`${post.title} - Cuuno`}
        titleTemplate={`${post.title}`}
      />
      <section className="cb-post">
        <div className="cb-container">
          <div className="cb-herosection">
            <div class="cb-herosection-wrap">
              <div class="cb-header">
                <h1>
                  {post.acf.ersteZeile} <br />
                  {post.acf.zweiteZeile} <br />
                  {post.acf.dritteZeile}
                </h1>
              </div>
              {/*    <div class="cb-text">
                <p>{post.acf.excerpt}</p>
              </div> */}
              <div class="cb-post-details">
                <div class="cb-post-details-col">
                  <div class="cb-post-details-wrapper">
                    <p class="cb-post-details-title">Kategorie:</p>
                    <div class="cb-post-details-content">
                      <CategorySVG />
                      <div class="cb-post-details-content-text">
                        <h1>{post.categories[0].name}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cb-post-details-col">
                  <div class="cb-post-details-wrapper">
                    <p class="cb-post-details-title">Datum:</p>
                    <div class="cb-post-details-content">
                      <TimeSVG />
                      <div class="cb-post-details-content-text">
                        <h1>{formatDate(new Date(post.date))}</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cb-post-details-col">
                  <div class="cb-post-details-wrapper">
                    <p class="cb-post-details-title">Lesedauer:</p>
                    <div class="cb-post-details-content">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 412.72 412.72"
                        enableBackground="new 0 0 412.72 412.72;"
                      >
                        <path
                          d="M404.72,82.944c-0.027,0-0.054,0-0.08,0h0h-27.12v-9.28c0.146-3.673-2.23-6.974-5.76-8
			c-18.828-4.934-38.216-7.408-57.68-7.36c-32,0-75.6,7.2-107.84,40c-32-33.12-75.92-40-107.84-40
			c-19.464-0.048-38.852,2.426-57.68,7.36c-3.53,1.026-5.906,4.327-5.76,8v9.2H8c-4.418,0-8,3.582-8,8v255.52c0,4.418,3.582,8,8,8
			c1.374-0.004,2.724-0.362,3.92-1.04c0.8-0.4,80.8-44.16,192.48-16h1.2h0.72c0.638,0.077,1.282,0.077,1.92,0
			c112-28.4,192,15.28,192.48,16c2.475,1.429,5.525,1.429,8,0c2.46-1.42,3.983-4.039,4-6.88V90.944
			C412.72,86.526,409.139,82.944,404.72,82.944z M16,333.664V98.944h19.12v200.64c-0.05,4.418,3.491,8.04,7.909,8.09
			c0.432,0.005,0.864-0.025,1.291-0.09c16.55-2.527,33.259-3.864,50-4c23.19-0.402,46.283,3.086,68.32,10.32
			C112.875,307.886,62.397,314.688,16,333.664z M94.32,287.664c-14.551,0.033-29.085,0.968-43.52,2.8V79.984
			c15.576-3.47,31.482-5.241,47.44-5.28c29.92,0,71.2,6.88,99.84,39.2l0.24,199.28C181.68,302.304,149.2,287.664,94.32,287.664z
			 M214.32,113.904c28.64-32,69.92-39.2,99.84-39.2c15.957,0.047,31.863,1.817,47.44,5.28v210.48
			c-14.354-1.849-28.808-2.811-43.28-2.88c-54.56,0-87.12,14.64-104,25.52V113.904z M396.64,333.664
			c-46.496-19.028-97.09-25.831-146.96-19.76c22.141-7.26,45.344-10.749,68.64-10.32c16.846,0.094,33.663,1.404,50.32,3.92
			c4.368,0.663,8.447-2.341,9.11-6.709c0.065-0.427,0.095-0.859,0.09-1.291V98.944h19.12L396.64,333.664z"
                        />
                      </svg>

                      <div class="cb-post-details-content-text">
                        <h1>{calculateReadingTime(post.content)}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <article class="cb-post-content">
          <div class="cb-container">
            <Image
              fadeIn={true}
              fluid={post.featured_media.localFile.childImageSharp.fluid}
            />
          </div>

          <div class="cb-container -md">
            <div class="cb-text cb-post-text">
              <p dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </div>
        </article>

        <div class="cb-post-socials">
          <div class="cb-container -md">
            <div class="cb-post-socials-wrapper">
              <p>Beitrag auf Social Media teilen</p>
              <ul class="cb-post-socials-list">
                <li>
                  <a
                    class="cb-post-socials-list-link"
                    href={`https://twitter.com/home?status=${linkPost}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="twitter"
                  >
                    <svg
                      version="1.1"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                    >
                      <path
                        d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
			c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
			c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
			c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
			c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
			c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
			C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
			C480.224,136.96,497.728,118.496,512,97.248z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="cb-post-socials-list-link"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkPost}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="facebook"
                  >
                    <svg
                      enableBackground="new 0 0 24 24"
                      height="512"
                      viewBox="0 0 24 24"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="cb-post-socials-list-link"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkPost}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="linkedin"
                  >
                    <svg
                      height="512"
                      viewBox="0 0 24 24"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path>
                      <path d="m.396 7.977h4.976v16.023h-4.976z"></path>
                      <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*   <div class="cb-post-related">
          <div class="cb-container">
            <div class="cb-post-related-wrapper">
              <p>You might also like</p>
              <div class="cb-post-related-grid">
                <div class="cb-post-related-grid-col"></div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      acf {
        ersteZeile
        zweiteZeile
        dritteZeile
        excerpt
      }
      date
      categories {
        name
      }
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allWordpressPost {
      edges {
        node {
          id
        }
      }
    }
  }
`
